"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectDestructuringEmpty2 = _interopRequireDefault(require("@babel/runtime/helpers/objectDestructuringEmpty"));
var _react = _interopRequireDefault(require("react"));
var _PlannerMonth = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/calendar/PlannerMonth"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var __jsx = _react.default.createElement;
const ActivityPlannerMonth = _ref => {
  let props = Object.assign({}, ((0, _objectDestructuringEmpty2.default)(_ref), _ref));
  return __jsx(StyledPlannerMonth, props);
};
const StyledPlannerMonth = (0, _styledComponents.default)(_PlannerMonth.default).withConfig({
  displayName: "ActivityPlannerMonth__StyledPlannerMonth",
  componentId: "j8eaz0-0"
})(["@media screen and (min-width:", "){width:100%;}@media screen and (min-width:", "){width:100%;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.xl);
var _default = exports.default = ActivityPlannerMonth;