"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWeekday = exports.getCurrentWeekday = exports.Weekdays = void 0;
const Weekdays = exports.Weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const getWeekday = date => date.toLocaleDateString('en-US', {
  weekday: 'long'
}).toLowerCase();
exports.getWeekday = getWeekday;
const getCurrentWeekday = () => getWeekday(new Date());
exports.getCurrentWeekday = getCurrentWeekday;