"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var _graphql = require("../generated/graphql");
var _useCart = require("../hooks/useCart");
var _parseDateTime = _interopRequireDefault(require("../utils/parseDateTime"));
var _ActivityPlanner = require("./ActivityPlanner");
var _BookButton = _interopRequireDefault(require("./designsystem/BookButton"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var __jsx = _react.default.createElement;
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ActivityPlannerTimeslots = ({
  timeslots,
  selectedDay,
  className,
  activity,
  setTimeslot
}) => {
  const cart = (0, _useCart.useCart)();
  const intl = (0, _reactIntl.useIntl)();
  if (!activity) {
    return null;
  }
  const timeslotsAvailable = !!timeslots.length;
  return __jsx(Container, {
    className: className
  }, __jsx(StyledBox, {
    className: "gt-s"
  }, __jsx(StyledHeading, {
    variant: "small"
  }, __jsx(_Title.default, {
    className: "gt-l"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "2HnMKL",
    defaultMessage: [{
      "type": 1,
      "value": "optionsQuantity"
    }, {
      "type": 0,
      "value": " beschikbare opties"
    }],
    values: {
      optionsQuantity: timeslots.length
    }
  })), __jsx(_Title.default, {
    className: "lt-l"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "lotQWr",
    defaultMessage: [{
      "type": 0,
      "value": "Beschikbare tijden op "
    }, {
      "type": 1,
      "value": "date"
    }],
    values: {
      date: __jsx(_reactIntl.FormattedDate, {
        value: selectedDay,
        format: "FULLWEEKDAYMONTHYEAR"
      })
    }
  }))), __jsx(DateBody, null, __jsx(_reactIntl.FormattedDate, {
    value: selectedDay,
    format: "WEEKDAYMONTHYEAR"
  }))), __jsx(StyledSlotBoxWrapper, {
    flex: 1
  }, !timeslotsAvailable && __jsx(_grid.Box, null, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "7bp06f",
    defaultMessage: [{
      "type": 0,
      "value": "Deze activiteit vindt niet plaats op deze dag"
    }]
  }))), timeslotsAvailable && timeslots.sort((slotA, slotB) => new Date(slotA.startDateTime).valueOf() - new Date(slotB.startDateTime).valueOf()).map((slot, index) => {
    const timeslotIsInCart = cart.cartQuery.data?.cart?.status === _graphql.CartStatusEnum.Open && cart.tickets.some(cartTicket => cartTicket.timeslot?.id === slot.id);

    // if allotment is null it means there's unlimited supply
    const allotmentAvailable = !!slot.allotment || slot.allotment === null;
    const timeslotHasExpired = !(0, _ActivityPlanner.isUpcoming)(slot, true);
    const bookButton = renderBookButton(activity, allotmentAvailable, timeslotHasExpired, setTimeslot, slot, timeslotIsInCart, intl);
    return __jsx(StyledSlotBox, {
      key: index
    }, __jsx(_grid.Flex, {
      flexDirection: ['column']
    }, __jsx(_grid.Box, {
      width: [1, null, 1 / 3]
    }, __jsx(SlotDate, null, __jsx(_reactIntl.FormattedTime, {
      value: (0, _parseDateTime.default)(slot.startDateTime)
    }), " \u2014", ' ', __jsx(_reactIntl.FormattedTime, {
      value: (0, _parseDateTime.default)(slot.endDateTime)
    })), slot.label && __jsx(_grid.Box, {
      mt: 4,
      style: {
        maxWidth: '25rem'
      }
    }, __jsx(TimeslotLabel, null, slot.label))), __jsx(ActivityItemwrapper, null, __jsx(ActivityRateWrapper, null, slot.rateGroup?.rates.map(rate => __jsx(_grid.Flex, {
      key: rate.name,
      justifyContent: "space-between",
      alignItems: "end"
    }, __jsx(_grid.Box, null, __jsx(TicketBody, null, rate.name)), __jsx(_grid.Box, null, __jsx(TicketPrice, null, !rate.price && __jsx(_reactIntl.FormattedMessage, {
      id: "I89Exj",
      defaultMessage: [{
        "type": 0,
        "value": "gratis"
      }]
    }), !!rate.price && __jsx(_reactIntl.FormattedNumber, {
      value: rate.price,
      format: "EUR"
    })))))), __jsx(BookButtonWrapper, {
      mt: [5, null, 0],
      flex: 1,
      hasAllotmentAvailable: (slot.allotment && slot.allotment < 10) ?? false
    }, allotmentAvailable && slot.allotment && slot.allotment < 10 && activity.bookingType === _graphql.ActivityBookingTypesEnum.Direct && __jsx(Label, {
      style: {
        textAlign: 'end'
      }
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "BH6OKl",
      defaultMessage: [{
        "type": 0,
        "value": "Nog "
      }, {
        "type": 1,
        "value": "allotment"
      }, {
        "type": 0,
        "value": " beschikbaar"
      }],
      values: {
        allotment: slot.allotment ?? 0
      }
    })), __jsx(_grid.Flex, {
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      my: "auto"
    }, bookButton, !bookButton && __jsx(_react.default.Fragment, null, allotmentAvailable && timeslotHasExpired && __jsx(_Body.default, {
      variant: "small"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "8L97Hs",
      defaultMessage: [{
        "type": 0,
        "value": "Verkoopperiode verlopen"
      }]
    })), !allotmentAvailable && activity.bookingType === _graphql.ActivityBookingTypesEnum.Direct && __jsx(_Button.default, {
      disabled: true
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "+CZOlz",
      defaultMessage: [{
        "type": 0,
        "value": "Uitverkocht"
      }]
    })), activity.bookingType !== _graphql.ActivityBookingTypesEnum.Direct && __jsx(_Body.default, {
      variant: "small"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "03L4Nm",
      defaultMessage: [{
        "type": 0,
        "value": "Geen verkoop via ons"
      }]
    }))))))));
  })));
};
const renderBookButton = (activity, allotmentAvailable, timeslotHasExpired, setTimeslot, slot, timeslotIsInCart, intl) => {
  if (activity.bookingType === _graphql.ActivityBookingTypesEnum.External && activity.externalBookingUrl) {
    return __jsx(_BookButton.default, {
      variant: "primary",
      target: "_blank",
      as: "a",
      style: {
        whiteSpace: 'nowrap'
      },
      href: activity.externalBookingUrl
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "/oUZou",
      defaultMessage: [{
        "type": 0,
        "value": "Koop tickets direct bij de aanbieder"
      }]
    }));
  }
  if (allotmentAvailable && !timeslotHasExpired && activity.bookingType === _graphql.ActivityBookingTypesEnum.Direct) {
    return __jsx(_BookButton.default, (0, _extends2.default)({
      onClick: () => setTimeslot(slot)
    }, timeslotIsInCart && {
      variant: 'outline'
    }), timeslotIsInCart ? __jsx(_reactIntl.FormattedMessage, {
      id: "3UhwoB",
      defaultMessage: [{
        "type": 0,
        "value": "Wijzig tickets"
      }]
    }) : intl.formatMessage(_messages.default.buyTickets));
  }
  return null;
};
var _default = exports.default = ActivityPlannerTimeslots;
const Container = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlannerTimeslots__Container",
  componentId: "sc-9pi3ng-0"
})(["background:white;width:100%;overflow:hidden;position:relative;"]);
const TimeslotLabel = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'small'
}).withConfig({
  displayName: "ActivityPlannerTimeslots__TimeslotLabel",
  componentId: "sc-9pi3ng-1"
})(["color:", ";font-weight:300;"], ({
  theme
}) => theme.colors.neutral[50]);
const Label = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'tiny'
}).withConfig({
  displayName: "ActivityPlannerTimeslots__Label",
  componentId: "sc-9pi3ng-2"
})(["font-weight:600;font-size:1.4rem;line-height:2.4rem;margin-bottom:0.4rem;"]);
const TicketBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityPlannerTimeslots__TicketBody",
  componentId: "sc-9pi3ng-3"
})(["font-weight:400;font-size:1.4rem;line-height:2rem;margin-right:0.4rem;@media screen and (max-width:720px){font-size:1rem;}"]);
const StyledBox = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ActivityPlannerTimeslots__StyledBox",
  componentId: "sc-9pi3ng-4"
})(["display:flex;justify-content:space-between;margin-bottom:2.4rem;"]);
const DateBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityPlannerTimeslots__DateBody",
  componentId: "sc-9pi3ng-5"
})(["font-size:1.6rem;font-weight:400;"]);
const StyledHeading = (0, _styledComponents.default)(_Heading.default).withConfig({
  displayName: "ActivityPlannerTimeslots__StyledHeading",
  componentId: "sc-9pi3ng-6"
})(["margin-bottom:0;"]);
const StyledSlotBoxWrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ActivityPlannerTimeslots__StyledSlotBoxWrapper",
  componentId: "sc-9pi3ng-7"
})(["position:absolute;left:0;top:5rem;right:0;bottom:0;overflow:auto;height:70%;@media screen and (max-width:1024px){top:8rem;}@media screen and (max-width:720px){position:relative;top:0;}"]);
const StyledSlotBox = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ActivityPlannerTimeslots__StyledSlotBox",
  componentId: "sc-9pi3ng-8"
})(["border:0.1rem solid #e0e0e0;border-radius:0.8rem;padding:1.6rem;margin-bottom:1.2rem;"]);
const SlotDate = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ActivityPlannerTimeslots__SlotDate",
  componentId: "sc-9pi3ng-9"
})(["font-weight:700;font-size:1.6rem;line-height:2.4rem;"]);
const ActivityItemwrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ActivityPlannerTimeslots__ActivityItemwrapper",
  componentId: "sc-9pi3ng-10"
})(["display:flex;align-items:flex-end;justify-content:space-between;@media screen and (max-width:", "){flex-direction:column;}"], ({
  theme
}) => theme.mediaQueries.s);
const ActivityRateWrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "ActivityPlannerTimeslots__ActivityRateWrapper",
  componentId: "sc-9pi3ng-11"
})(["display:flex;flex-wrap:wrap;flex-flow:column;flex:1;@media screen and (max-width:", "){align-self:flex-start;width:100%;}"], ({
  theme
}) => theme.mediaQueries.s);
const TicketPrice = (0, _styledComponents.default)(TicketBody).withConfig({
  displayName: "ActivityPlannerTimeslots__TicketPrice",
  componentId: "sc-9pi3ng-12"
})([""]);
const BookButtonWrapper = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "ActivityPlannerTimeslots__BookButtonWrapper",
  componentId: "sc-9pi3ng-13"
})(["display:flex;flex-direction:column;@media screen and (max-width:720px){flex-direction:row;justify-content:", ";}"], p => p.hasAllotmentAvailable ? 'space-between' : 'flex-end');