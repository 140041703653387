"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _Stepper = require("@oberoninternal/travelbase-ds/components/form/Stepper");
var _portal = _interopRequireDefault(require("@reach/portal"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _ErrorMessage = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ErrorMessage"));
var _reactIntl = require("react-intl");
var _client = require("@apollo/client");
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var _parseDateTime = _interopRequireDefault(require("../utils/parseDateTime"));
var _BookButton = _interopRequireDefault(require("./designsystem/BookButton"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _StickyBar = _interopRequireDefault(require("./StickyBar"));
var _Edit = _interopRequireDefault(require("./svg/Edit.svg"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment ActivityCheckoutModalTimeslot on Timeslot {
        id
        startDateTime
        endDateTime
        allotment
        rateGroup {
            rates {
                id
                name
                price
            }
        }
    }
`;
const computeTotalPersons = values => Object.values(values).reduce((total, next) => total + next.amount, 0);
const ActivityTickets = ({
  onClose,
  timeslot,
  ticket,
  setTicket,
  setEdit,
  setStickBar
}) => {
  const initialValues = (0, _react.useMemo)(() => Object.assign({}, ...(timeslot?.rateGroup?.rates.map(rate => ({
    [rate.id]: {
      amount: ticket?.rateLines.find(rateLine => rateLine.rateId === rate.id)?.amount ?? 0
    }
  })) ?? [])), [ticket, timeslot]);
  const intl = (0, _reactIntl.useIntl)();
  const {
    0: removing,
    1: setRemoving
  } = (0, _react.useState)(false);
  const onRemove = async (disableIndicator = false) => {
    if (!timeslot || !window.confirm(intl.formatMessage({
      "id": "0I4GMc",
      "defaultMessage": [{
        "type": 0,
        "value": "Weet je zeker dat je de "
      }, {
        "type": 1,
        "value": "type"
      }, {
        "type": 0,
        "value": " uit je mandje wilt verwijderen?"
      }]
    }, {
      type: intl.formatMessage({
        "id": "2PjVlv",
        "defaultMessage": [{
          "type": 0,
          "value": "activiteit"
        }]
      })
    }))) {
      return;
    }
    if (!disableIndicator) {
      setRemoving(true);
    }
    await setTicket?.(null);
    if (!disableIndicator) {
      setRemoving(false);
    }
    onClose();
  };
  return __jsx(TicketsWrapper, null, __jsx(_grid.Box, {
    width: ['unset', null, '30%']
  }, __jsx(EditWrapper, null, __jsx(EditLabelWrapper, null, __jsx(EditLabel, null, __jsx(_reactIntl.FormattedDate, {
    value: (0, _parseDateTime.default)(timeslot?.startDateTime ?? ''),
    format: "WEEKDAYMONTHYEAR"
  })), __jsx(EditLabel, null, __jsx(_reactIntl.FormattedTime, {
    value: (0, _parseDateTime.default)(timeslot?.startDateTime ?? '')
  }), " \u2014", ' ', __jsx(_reactIntl.FormattedTime, {
    value: (0, _parseDateTime.default)(timeslot?.endDateTime ?? '')
  }))), __jsx(EditIconWrapper, null, __jsx("button", {
    type: "button",
    onClick: setEdit
  }, __jsx(_Edit.default, null)))), __jsx(MobileWrapper, null, __jsx(MobileEditLabelWrapper, null, __jsx(ChooseTicketsTitle, null, __jsx(_reactIntl.FormattedMessage, {
    id: "U7aHcf",
    defaultMessage: [{
      "type": 0,
      "value": "Koop Tickets"
    }]
  })), __jsx(EditLabel, null, __jsx(_reactIntl.FormattedDate, {
    value: (0, _parseDateTime.default)(timeslot?.startDateTime ?? ''),
    format: "WEEKDAYMONTHYEAR"
  })), __jsx(EditLabel, null, __jsx(_reactIntl.FormattedTime, {
    value: (0, _parseDateTime.default)(timeslot?.startDateTime ?? '')
  }), " \u2014", ' ', __jsx(_reactIntl.FormattedTime, {
    value: (0, _parseDateTime.default)(timeslot?.endDateTime ?? '')
  }))))), __jsx(_grid.Box, {
    width: ['unset', null, '70%'],
    px: [0, null, 4]
  }, __jsx(_formik.Formik, {
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async values => {
      if (!timeslot) {
        return;
      }
      const persons = computeTotalPersons(values);
      if (!persons) {
        await onRemove(true);
        return;
      }
      await setTicket?.({
        timeslotId: timeslot.id,
        rateLines: Object.entries(values).map(([key, value]) => ({
          rateId: key,
          amount: value.amount
        })).filter(rateLine => !!rateLine.amount)
      });
      onClose();
    }
  }, ({
    values,
    dirty,
    isSubmitting,
    submitForm
  }) => {
    if (!Object.keys(values).length) {
      return null;
    }
    const persons = computeTotalPersons(values);
    const ticketBarContent = __jsx(_react.default.Fragment, null, ticket && __jsx(_grid.Box, {
      mr: 3
    }, __jsx(_Button.default, {
      disabled: isSubmitting,
      submitting: removing,
      onClick: () => onRemove(),
      variant: "danger"
    }, "Verwijderen")), typeof timeslot?.allotment === 'number' && persons >= timeslot.allotment && __jsx(_grid.Box, {
      mr: 3
    }, __jsx(_ErrorMessage.default, null, intl.formatMessage({
      "id": "6IzYKY",
      "defaultMessage": [{
        "type": 0,
        "value": "Er "
      }, {
        "type": 6,
        "pluralType": "cardinal",
        "value": "allotment",
        "offset": 0,
        "options": {
          "one": {
            "value": [{
              "type": 0,
              "value": "is slechts "
            }, {
              "type": 7
            }]
          },
          "other": {
            "value": [{
              "type": 0,
              "value": "zijn slechts "
            }, {
              "type": 7
            }]
          }
        }
      }, {
        "type": 0,
        "value": " beschikbaar"
      }]
    }, {
      allotment: timeslot.allotment
    }))), !ticket && __jsx(ButtonGroup, null, __jsx(StyledBookButton, {
      onClick: submitForm,
      disabled: !dirty || removing,
      type: "submit",
      submitting: isSubmitting
    }, intl.formatMessage(_messages.default.buyTickets))), ticket && __jsx(ButtonGroup, null, __jsx(StyledBookButton, {
      onClick: () => {
        if (dirty) {
          submitForm();
        } else {
          onClose();
        }
      },
      type: "submit",
      submitting: isSubmitting
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "niEu50",
      defaultMessage: [{
        "type": 0,
        "value": "Aanpassen"
      }]
    }))));
    return __jsx(_react.default.Fragment, null, timeslot && __jsx(_react.default.Fragment, null, __jsx(_Stack.default, {
      spacing: 3
    }, timeslot.rateGroup?.rates.map(rate => {
      const total = rate.price * values[rate.id].amount;
      return __jsx(ActivityItems, {
        key: rate.id,
        alignItems: "center"
      }, __jsx(_grid.Flex, {
        flex: 2,
        flexDirection: ['column', null, 'row']
      }, __jsx(_grid.Box, {
        flex: 1
      }, __jsx(TicketBody, null, rate.name)), __jsx(_grid.Box, {
        flex: 1
      }, __jsx(_Body.default, null, !rate.price && __jsx(_reactIntl.FormattedMessage, {
        id: "I89Exj",
        defaultMessage: [{
          "type": 0,
          "value": "gratis"
        }]
      }), !!rate.price && __jsx(_reactIntl.FormattedNumber, {
        value: rate.price,
        format: "EUR"
      })))), __jsx(_grid.Box, {
        flex: 2
      }, __jsx(_Stepper.StepperField, {
        editable: true,
        variant: "primary",
        name: `['${rate.id}'].amount`,
        maximum: (timeslot.allotment ?? 100) - persons + values[rate.id].amount
      })), __jsx(_grid.Flex, {
        flex: 1,
        justifyContent: "flex-end"
      }, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedNumber, {
        value: total,
        format: "EUR"
      }))));
    }), __jsx(TotalWrapper, {
      justifyContent: "space-between"
    }, __jsx(_grid.Box, null, __jsx(TotalLabel, {
      variant: "tiny",
      style: {
        fontWeight: 300
      }
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "+i11sY",
      defaultMessage: [{
        "type": 0,
        "value": "totaal"
      }]
    }))), __jsx(_grid.Box, null, __jsx(TicketPrice, null, __jsx(_reactIntl.FormattedNumber, {
      value: timeslot.rateGroup?.rates.reduce((acc, next) => acc + next.price * values[next.id].amount, 0) ?? 0,
      format: "EUR"
    })))))), __jsx(StyledTicketBar, {
      className: "gt-s"
    }, ticketBarContent), setStickBar && __jsx(_portal.default, null, __jsx(TicketBarMobile, {
      className: "lt-s"
    }, ticketBarContent)));
  })));
};
var _default = exports.default = ActivityTickets;
const ButtonGroup = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__ButtonGroup",
  componentId: "sc-4m6vun-0"
})(["> button + button{margin-left:", ";}> button{flex-shrink:0;}display:flex;margin-left:auto;@media screen and (max-width:720px){width:100%;}"], ({
  theme
}) => theme.spacing['40_Standard']);
const TicketBarMobile = (0, _styledComponents.default)(_StickyBar.default).attrs({
  wrapInPortal: true
}).withConfig({
  displayName: "ActivityTickets__TicketBarMobile",
  componentId: "sc-4m6vun-1"
})(["display:flex;width:100%;align-items:center;> div > div{height:unset;}"]);
const TicketBar = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__TicketBar",
  componentId: "sc-4m6vun-2"
})(["align-items:center;padding-top:2.4rem;margin-top:auto;@media screen and (min-width:", "){margin-top:", ";}border-top:1px solid ", ";display:flex;"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.colors.neutral[20]);
const TicketBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityTickets__TicketBody",
  componentId: "sc-4m6vun-3"
})(["font-weight:400;"]);
const TicketPrice = (0, _styledComponents.default)(TicketBody).withConfig({
  displayName: "ActivityTickets__TicketPrice",
  componentId: "sc-4m6vun-4"
})(["font-weight:600;font-size:2rem;line-height:2.4rem;"]);
const TicketsWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__TicketsWrapper",
  componentId: "sc-4m6vun-5"
})(["display:flex;@media screen and (max-width:1024px){display:block;}"]);
const EditWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__EditWrapper",
  componentId: "sc-4m6vun-6"
})(["display:flex;border:1px solid ", ";border-radius:8px;padding:1.6rem;@media screen and (max-width:720px){display:none;}"], ({
  theme
}) => theme.colors.neutral[10]);
const EditLabelWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__EditLabelWrapper",
  componentId: "sc-4m6vun-7"
})(["width:50%;"]);
const MobileWrapper = (0, _styledComponents.default)(EditLabelWrapper).withConfig({
  displayName: "ActivityTickets__MobileWrapper",
  componentId: "sc-4m6vun-8"
})(["display:none;@media screen and (max-width:720px){display:block;width:100%;padding-top:2rem;justify-content:space-between;}"]);
const MobileEditLabelWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__MobileEditLabelWrapper",
  componentId: "sc-4m6vun-9"
})(["display:block;"]);
const EditIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityTickets__EditIconWrapper",
  componentId: "sc-4m6vun-10"
})(["width:50%;display:flex;justify-content:flex-end;@media screen and (max-width:720px){display:none;}"]);
const EditLabel = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityTickets__EditLabel",
  componentId: "sc-4m6vun-11"
})(["font-weight:400;font-size:1.6rem;line-height:2.4rem;"]);
const StyledTicketBar = (0, _styledComponents.default)(TicketBar).withConfig({
  displayName: "ActivityTickets__StyledTicketBar",
  componentId: "sc-4m6vun-12"
})(["border-top:none;"]);
const TotalWrapper = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "ActivityTickets__TotalWrapper",
  componentId: "sc-4m6vun-13"
})(["margin-top:2.4rem !important;"]);
const TotalLabel = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityTickets__TotalLabel",
  componentId: "sc-4m6vun-14"
})(["text-transform:capitalize;font-weight:600 !important;font-size:2rem;line-height:2.4rem;"]);
const ActivityItems = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "ActivityTickets__ActivityItems",
  componentId: "sc-4m6vun-15"
})(["border-bottom:1px solid ", ";padding-bottom:1.6rem;display:flex;justify-content:space-between;"], ({
  theme
}) => theme.colors.neutral[10]);
const StyledBookButton = (0, _styledComponents.default)(_BookButton.default).withConfig({
  displayName: "ActivityTickets__StyledBookButton",
  componentId: "sc-4m6vun-16"
})(["width:100%;display:flex;justify-content:center;align-items:center;padding:1.2rem 3.2rem;background-color:", ";border-radius:99px;color:", ";font-weight:600;font-size:1.4rem;line-height:2.4rem;"], ({
  theme
}) => theme.colors.primary[80], ({
  theme
}) => theme.colors.primary[0]);
const ChooseTicketsTitle = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityTickets__ChooseTicketsTitle",
  componentId: "sc-4m6vun-17"
})(["font-weight:700;font-size:2.4rem;line-height:2.4rem;margin-bottom:1.6rem;"]);