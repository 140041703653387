"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ticketsAvailable = exports.slotIsFull = exports.slotIsBookable = exports.pageQuery = exports.isUpcoming = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _client = require("@apollo/client");
var _addHours = _interopRequireDefault(require("date-fns/addHours"));
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _isWithinInterval = _interopRequireDefault(require("date-fns/isWithinInterval"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _startOfToday = _interopRequireDefault(require("date-fns/startOfToday"));
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _graphql = require("../generated/graphql");
var _usePlannerFetcher = _interopRequireDefault(require("../hooks/planner/usePlannerFetcher"));
var _usePlannerQuery = _interopRequireDefault(require("../hooks/planner/usePlannerQuery"));
var _usePrevious = _interopRequireDefault(require("../hooks/usePrevious"));
var _parseDateTime = _interopRequireDefault(require("../utils/parseDateTime"));
var _weekday = require("../utils/weekday");
var _ActivityPlannerTimeslots = _interopRequireDefault(require("./ActivityPlannerTimeslots"));
var _Planner = _interopRequireWildcard(require("./Planner"));
var _StickyBar = _interopRequireDefault(require("./StickyBar"));
var _ActivityPlannerMonth = _interopRequireDefault(require("./ActivityPlannerMonth"));
var _ActivityPlannerController = _interopRequireDefault(require("./ActivityPlannerController"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _ActivityTickets = _interopRequireDefault(require("./ActivityTickets"));
var _grid = require("@rebass/grid");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const pageQuery = exports.pageQuery = (0, _client.gql)`
    query ActivityPlanner($slug: String!, $startDate: String!, $endDate: String!) {
        activity(slug: $slug) {
            id
            externalBookingUrl
            bookingType
            timeslots(startDate: $startDate, endDate: $endDate) {
                ...ActivityPlannerTimeslot
            }
        }
    }

    fragment ActivityPlannerTimeslot on Timeslot {
        id
        allotment
        startDateTime
        endDateTime
        label
        rateGroup {
            releaseHours
            rates {
                id
                name
                price
            }
        }
    }

    # unused in gql, but its purpose is to generate prop types from
    fragment ActivityPlannerActivity on Activity {
        name
        bookingType
        externalBookingUrl
        company {
            ...CompanyOperatingHours
        }
    }
`;
const filterWithInterval = (timeslots, interval, exclusive = false) => timeslots.filter(timeslot => {
  const within = (0, _isWithinInterval.default)((0, _parseDateTime.default)(timeslot.startDateTime), interval);
  return exclusive ? !within : within;
});
const isUpcoming = (slot, considerReleaseHours = false) =>
// consider release hours by subtracting these from the startDateTime
(0, _isAfter.default)((0, _addHours.default)((0, _parseDateTime.default)(slot.startDateTime), (considerReleaseHours && slot.rateGroup?.releaseHours ? slot.rateGroup?.releaseHours : 0) * -1), new Date());
exports.isUpcoming = isUpcoming;
const getSelectedDayData = (selectedDay, data, activity) => {
  const timeslotsForSelectedDay = selectedDay && data?.activity?.timeslots.filter(slot => (0, _isSameDay.default)((0, _parseDateTime.default)(slot.startDateTime), selectedDay) && isUpcoming(slot));
  const operatingHoursForSelectedDay = (selectedDay && activity?.company?.normalOperatingHours?.[(0, _weekday.getWeekday)(selectedDay)]) ?? [];
  return {
    timeslotsForSelectedDay,
    operatingHoursForSelectedDay
  };
};
const useSelectedDayJumper = (selectedDay, viewportRef, onSelectScrollOptions) => {
  const prev = (0, _usePrevious.default)(selectedDay);
  (0, _react.useEffect)(() => {
    if (selectedDay && viewportRef.current) {
      const {
        scrollRef
      } = onSelectScrollOptions ?? {};
      const {
        height,
        top
      } = viewportRef.current.getBoundingClientRect();
      const center = window.pageYOffset + top + height / 2;
      // scroll to the selected month
      viewportRef.current.plannerViewActions.jumpToMonth(selectedDay, true);

      // also scroll to the prices
      if (!(0, _lodash.default)(prev, selectedDay)) {
        const el = scrollRef?.current;
        el?.scrollTo({
          top: center,
          behavior: 'smooth'
        });
      }
    }
    // only run when the selected day changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);
};
const ticketsAvailable = (slot, bookingType, strict = false) => {
  if (bookingType === _graphql.ActivityBookingTypesEnum.External) {
    // TODO strict?
    return true;
  }
  if (bookingType === _graphql.ActivityBookingTypesEnum.Direct) {
    if (slot?.allotment !== null) {
      return (slot?.allotment ?? 0) > 0;
    }
  }
  return !strict;
};
exports.ticketsAvailable = ticketsAvailable;
const slotIsBookable = (slot, bookingType, selectedDay, strict = false) => {
  const hasTicketsAvailable = ticketsAvailable(slot, bookingType, strict);
  const hasExpired = !isUpcoming(slot, true);
  return (0, _isSameDay.default)(selectedDay, (0, _parseDateTime.default)(slot.startDateTime)) && hasTicketsAvailable && !hasExpired;
};
exports.slotIsBookable = slotIsBookable;
const slotIsFull = (slot, bookingType, strict = false) => {
  const hasExpired = !isUpcoming(slot, true);
  return !ticketsAvailable(slot, bookingType, strict) && !hasExpired;
};
exports.slotIsFull = slotIsFull;
const useDayModifier = (selectedDay, data) => (0, _react.useCallback)(day => {
  if ((0, _isBefore.default)(day, (0, _startOfToday.default)())) {
    return {
      variant: 'disabled'
    };
  }
  if (selectedDay && (0, _isSameDay.default)(selectedDay, day)) {
    return {
      variant: 'selected'
    };
  }
  for (const slot of data?.activity?.timeslots ?? []) {
    if ((0, _isSameDay.default)((0, _parseDateTime.default)(slot.startDateTime), day)) {
      if (slotIsBookable(slot, data?.activity?.bookingType ?? _graphql.ActivityBookingTypesEnum.NotBookable, day)) {
        return {
          variant: 'bookable'
        };
      }
      if (slotIsFull(slot, data?.activity?.bookingType ?? _graphql.ActivityBookingTypesEnum.NotBookable)) {
        return {
          variant: 'bookable-soldout'
        };
      }
      continue;
    }
  }
  return {
    variant: 'blocked'
  };
}, [data, selectedDay]);
const getStartOpts = () => ({
  startDate: (0, _parseToDateString.default)((0, _startOfMonth.default)(new Date())),
  endDate: (0, _parseToDateString.default)((0, _addMonths.default)((0, _startOfMonth.default)(new Date()), 1))
});
const ActivityPlanner = ({
  slug,
  selectedDay,
  setSelectedDay,
  setSelectedDayIsBookable,
  onSelectScrollOpts,
  activity,
  setTimeslot,
  isOpenModal,
  setTicket,
  activityTimeslot,
  ticket,
  openActivityTickets,
  openActivityPlanner,
  onBack,
  onActivityPlanner,
  onModalClose
}) => {
  const viewportRef = (0, _react.useRef)(null);
  const [{
    data,
    variables
  }, {
    query,
    readQuery,
    writeQuery
  }] = (0, _usePlannerQuery.default)(_objectSpread(_objectSpread({}, getStartOpts()), {}, {
    slug
  }), _graphql.ActivityPlannerDocument);
  const {
    timeslotsForSelectedDay
  } = getSelectedDayData(selectedDay, data, activity);
  const fetcher = (0, _react.useCallback)(async months => {
    // normally we'd use fetchMore for this but somehow it doesn't look in the cache first.
    // also because we can only fetch 1 month per request we'll batch them and execute them in parallel.
    const promises = [];
    for (const month of months) {
      const vars = {
        startDate: (0, _parseToDateString.default)(month),
        endDate: (0, _parseToDateString.default)((0, _endOfMonth.default)(month))
      };
      const execute = async () => {
        const mergedVars = _objectSpread(_objectSpread({}, variables), vars);
        const result = await query(mergedVars);
        const isMain = (0, _lodash.default)(mergedVars, variables);
        if (isMain) {
          const monthInterval = {
            start: new Date(variables.startDate),
            end: new Date(variables.endDate)
          };
          return filterWithInterval(result.data.activity?.timeslots ?? [], monthInterval);
        }
        return result.data.activity?.timeslots ?? [];
      };
      const promise = execute();
      promises.push(promise);
    }
    const results = await Promise.all(promises);
    const prev = readQuery();
    if (!prev?.activity) {
      return;
    }
    const monthsInterval = {
      start: (0, _startOfMonth.default)(months[0]),
      end: (0, _startOfMonth.default)((0, _addMonths.default)(months[months.length - 1], 1))
    };
    writeQuery(_objectSpread(_objectSpread({}, prev), {}, {
      activity: _objectSpread(_objectSpread({}, prev.activity), {}, {
        timeslots: [...filterWithInterval(prev.activity?.timeslots ?? [], monthsInterval, true), ...results.flat()]
      })
    }));
  }, [query, readQuery, variables, writeQuery]);
  const [, {
    isLoaded,
    loadMore
  }] = (0, _usePlannerFetcher.default)(fetcher);
  const dayModifier = useDayModifier(selectedDay, data);
  (0, _react.useEffect)(() => {
    if (!selectedDay && data) {
      const firstAvailableTimeSlot = data?.activity?.timeslots.filter(timeslot => isUpcoming(timeslot, true))?.sort((timeslotA, timeslotB) => (0, _parseDateTime.default)(timeslotA.startDateTime).valueOf() - (0, _parseDateTime.default)(timeslotB.startDateTime).valueOf())[0];
      if (firstAvailableTimeSlot) {
        setSelectedDay((0, _parseDateTime.default)(firstAvailableTimeSlot.startDateTime));
      }
    }
  }, [data, selectedDay, setSelectedDay]);
  const dayIsBookable = (0, _react.useMemo)(() => {
    if (selectedDay) {
      return !!data?.activity?.timeslots.find(slot => slotIsBookable(slot, activity?.bookingType ?? _graphql.ActivityBookingTypesEnum.NotBookable, selectedDay));
    }
    return false;
  }, [selectedDay, data?.activity?.timeslots, activity?.bookingType]);
  (0, _react.useEffect)(() => setSelectedDayIsBookable?.(dayIsBookable), [dayIsBookable, setSelectedDayIsBookable]);
  const {
    open,
    onClose,
    onOpen
  } = (0, _useSesame.default)();
  const onDayClick = (0, _react.useCallback)(({
    day
  }) => {
    if (selectedDay && (0, _isSameDay.default)(day, selectedDay)) {
      setSelectedDay(null);
    } else {
      setSelectedDay(day);
      onOpen();
    }
  }, [onOpen, selectedDay, setSelectedDay]);
  const intl = (0, _reactIntl.useIntl)();
  const validatedSetTimeslot = (0, _react.useCallback)(slot => {
    if (slot && !isUpcoming(slot, true)) {
      window.alert(intl.formatMessage({
        "id": "uNPGn7",
        "defaultMessage": [{
          "type": 0,
          "value": "Dit tijdslot is verlopen, kies een ander tijdslot"
        }]
      }));
      return;
    }
    setTimeslot(slot);
  }, [intl, setTimeslot]);
  const deviceSize = (0, _devicesize.useDeviceSize)();
  useSelectedDayJumper(selectedDay, viewportRef, onSelectScrollOpts);
  const onSetToday = () => setSelectedDay(new Date());
  const {
    0: openPlannerModal,
    1: setOpenPlannerModal
  } = (0, _react.useState)(isOpenModal);
  const openModal = () => {
    setOpenPlannerModal(false);
  };
  return __jsx(StyledPlannerContainer, null, deviceSize !== 'mobile' && __jsx(_react.default.Fragment, null, __jsx(PlannerWrapper, null, __jsx(PlannerCol, {
    size: 30
  }, __jsx(_Planner.default, {
    onDayClick: onDayClick,
    computeDayProps: dayModifier,
    monthsRef: viewportRef,
    isMonthLoaded: isLoaded,
    onInViewIntervalChange: loadMore,
    PlannerMonthComponent: _ActivityPlannerMonth.default,
    initialCenterMonth: selectedDay ?? undefined,
    isActivity: true
  }, __jsx(_ActivityPlannerController.default, {
    onToday: onSetToday
  }))), __jsx(PlannerCol, {
    size: 70,
    pSpace: 4,
    dl: "flex",
    ds: "none"
  }, selectedDay && timeslotsForSelectedDay && __jsx(_ActivityPlannerTimeslots.default, {
    activity: activity,
    className: "gt-s",
    selectedDay: selectedDay,
    timeslots: timeslotsForSelectedDay,
    setTimeslot: validatedSetTimeslot
  }))), __jsx(StyledStickyBar, {
    className: "lt-s",
    open: !!selectedDay
  }, __jsx(StyledTextButton, {
    onClick: openModal
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "efY4b5",
    defaultMessage: [{
      "type": 0,
      "value": "Bekijk tickets"
    }]
  })))), deviceSize === 'mobile' && __jsx(_react.default.Fragment, null, __jsx(_Modal.default, {
    open: openPlannerModal ?? false,
    onClose: onModalClose,
    onBack: !openActivityPlanner ? undefined : onBack
  }, __jsx(InnerModal, null, __jsx(_grid.Box, {
    style: {
      display: !openActivityPlanner ? 'block' : 'none'
    }
  }, __jsx(_Planner.default, {
    onDayClick: onDayClick,
    computeDayProps: dayModifier,
    monthsRef: viewportRef,
    isMonthLoaded: isLoaded,
    onInViewIntervalChange: loadMore,
    PlannerMonthComponent: _ActivityPlannerMonth.default,
    initialCenterMonth: selectedDay ?? undefined,
    isActivity: true
  }, __jsx(_ActivityPlannerController.default, {
    onToday: onSetToday
  })), __jsx(StyledStickyBar, {
    className: "lt-s",
    open: !!selectedDay
  }, __jsx(StyledTextButton, {
    onClick: onActivityPlanner
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "efY4b5",
    defaultMessage: [{
      "type": 0,
      "value": "Bekijk tickets"
    }]
  })))), openActivityPlanner && !openActivityTickets && __jsx(_react.default.Fragment, null, selectedDay && __jsx(StyledModalHeader, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "2HnMKL",
    defaultMessage: [{
      "type": 1,
      "value": "optionsQuantity"
    }, {
      "type": 0,
      "value": " beschikbare opties"
    }],
    values: {
      optionsQuantity: timeslotsForSelectedDay?.length
    }
  })), __jsx(DateTitle, null, __jsx(_reactIntl.FormattedDate, {
    value: selectedDay,
    format: "WEEKDAYMONTHYEAR"
  }))), selectedDay && timeslotsForSelectedDay && __jsx(_ActivityPlannerTimeslots.default, {
    activity: activity,
    selectedDay: selectedDay,
    timeslots: timeslotsForSelectedDay,
    setTimeslot: validatedSetTimeslot
  })), openActivityTickets && __jsx(_ActivityTickets.default, {
    activity: activity,
    setTicket: setTicket,
    onClose: onClose,
    open: open,
    timeslot: activityTimeslot,
    ticket: ticket,
    setStickBar: openActivityTickets
  })))));
};
const StyledStickyBar = (0, _styledComponents.default)(_StickyBar.default).withConfig({
  displayName: "ActivityPlanner__StyledStickyBar",
  componentId: "sc-18571t0-0"
})(["transform:", ";transition:transform 200ms ease-in-out;div{display:flex;justify-content:center;}"], ({
  open
}) => `translateY(${open ? 0 : '100%'})`);
const PlannerWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlanner__PlannerWrapper",
  componentId: "sc-18571t0-1"
})(["display:flex;"]);
const PlannerCol = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlanner__PlannerCol",
  componentId: "sc-18571t0-2"
})(["width:", ";padding:0rem ", ";display:", ";@media screen and (max-width:720px){width:100%;display:", ";padding-top:8rem;}"], p => `${p.size}%`, p => `${p.pSpace}rem`, p => `${p.dl}`, p => p.ds);
const StyledPlannerContainer = (0, _styledComponents.default)(_Planner.PlannerContainer).withConfig({
  displayName: "ActivityPlanner__StyledPlannerContainer",
  componentId: "sc-18571t0-3"
})(["@media screen and (max-width:720px){padding:0 2rem;}"]);
const StyledTextButton = _styledComponents.default.button.withConfig({
  displayName: "ActivityPlanner__StyledTextButton",
  componentId: "sc-18571t0-4"
})(["width:100%;display:flex;justify-content:center;align-items:center;padding:1.2rem 3.2rem;background-color:", ";border-radius:9.9rem;color:", ";font-weight:600;font-size:1.4rem;line-height:2.4rem;"], ({
  theme
}) => theme.colors.primary[80], ({
  theme
}) => theme.colors.neutral[0]);
const StyledModalHeader = (0, _styledComponents.default)(_ModalHeader.default).withConfig({
  displayName: "ActivityPlanner__StyledModalHeader",
  componentId: "sc-18571t0-5"
})(["justify-content:flex-start;border-bottom:none;margin-bottom:3rem;"]);
const DateTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "ActivityPlanner__DateTitle",
  componentId: "sc-18571t0-6"
})(["font-size:1.4rem;font-weight:400;"]);
const InnerModal = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlanner__InnerModal",
  componentId: "sc-18571t0-7"
})(["padding-top:5rem;"]);
var _default = exports.default = ActivityPlanner;