"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useIntervalClick = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useIntervalClick"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Planner = require("./Planner");
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const ActivityPlannerController = ({
  loading,
  onToday
}) => {
  const plannerViewActions = (0, _react.useContext)(_Planner.PlannerViewActionsContext);
  const plannerViewState = (0, _react.useContext)(_Planner.PlannerViewContext);
  const deviceSize = (0, _devicesize.useDeviceSize)();
  if (!plannerViewActions || !plannerViewState) {
    throw new Error('PlannerControls can only be used within a Planner component');
  }
  const {
    scrollBy,
    getMonthDimensions,
    scrollToToday
  } = plannerViewActions;
  const {
    monthsInView
  } = plannerViewState;
  const {
    0: month,
    1: setMonth
  } = (0, _react.useState)(0);
  const useScrollByMonthWidth = (positive = true) => (0, _useIntervalClick.default)(() => {
    if (positive) {
      setMonth(month + 1);
    } else {
      setMonth(month - 1);
    }
    const monthWidth = Math.floor(getMonthDimensions());
    scrollBy(positive ? monthWidth : -monthWidth, !loading);
  }, 500);
  const incrementScrollProps = useScrollByMonthWidth();
  const decrementScrollProps = useScrollByMonthWidth(false);
  const toTodayMobile = () => {
    scrollToToday();
    setMonth(0);
  };
  const toToday = () => {
    setMonth(0);
    onToday();
  };
  return __jsx(ControlsWrapper, null, __jsx(ControlsHeader, null, __jsx(HeaderLeabel, null, __jsx(_reactIntl.FormattedMessage, {
    id: "wNjQfw",
    defaultMessage: [{
      "type": 0,
      "value": "Selecteer een datum"
    }]
  })), deviceSize === 'mobile' && __jsx(StyledTextButton, {
    onClick: () => toTodayMobile(),
    disabled: month === 0
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "q9hRlN",
    defaultMessage: [{
      "type": 0,
      "value": "Vandaag"
    }]
  })), deviceSize !== 'mobile' && __jsx(StyledTextButton, {
    onClick: () => toToday(),
    disabled: monthsInView?.[0] === 0
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "q9hRlN",
    defaultMessage: [{
      "type": 0,
      "value": "Vandaag"
    }]
  }))), __jsx(Controls, null, deviceSize === 'mobile' && __jsx(StyledRoundButton, (0, _extends2.default)({
    disabled: month === 0
  }, decrementScrollProps), __jsx(_ArrowLeft.default, null)), deviceSize !== 'mobile' && __jsx(StyledRoundButton, (0, _extends2.default)({
    disabled: monthsInView?.[0] === 0
  }, decrementScrollProps), __jsx(_ArrowLeft.default, null)), __jsx(StyledRoundButton, incrementScrollProps, __jsx(_ArrowRight.default, null))));
};
var _default = exports.default = ActivityPlannerController;
const ControlsWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlannerController__ControlsWrapper",
  componentId: "z1mc8z-0"
})([""]);
const ControlsHeader = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlannerController__ControlsHeader",
  componentId: "z1mc8z-1"
})(["margin-bottom:3rem;display:flex;justify-content:space-between;align-items:center;"]);
const HeaderLeabel = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityPlannerController__HeaderLeabel",
  componentId: "z1mc8z-2"
})(["font-weight:700;font-size:2rem;line-height:2.6rem;"]);
const Controls = _styledComponents.default.div.withConfig({
  displayName: "ActivityPlannerController__Controls",
  componentId: "z1mc8z-3"
})(["display:flex;align-items:center;justify-content:space-between;margin-left:auto;> button + button{margin-left:", ";}"], ({
  theme
}) => theme.spacing['30_Small']);
const StyledRoundButton = _styledComponents.default.button.withConfig({
  displayName: "ActivityPlannerController__StyledRoundButton",
  componentId: "z1mc8z-4"
})(["opacity:", ";cursor:", ";svg{height:1.5rem;width:1.5rem;}"], ({
  disabled
}) => disabled ? '0.4' : '1', ({
  disabled
}) => disabled ? 'not-allowed' : 'pointer');
const StyledTextButton = _styledComponents.default.button.withConfig({
  displayName: "ActivityPlannerController__StyledTextButton",
  componentId: "z1mc8z-5"
})(["background:", ";border-radius:9.9rem;padding:0.4rem 1.6rem;font-weight:600;font-size:1.4rem;color:", ";opacity:", ";cursor:", ";:hover{text-decoration:none !important;color:", ";}"], ({
  theme
}) => theme.colors.neutral[5], ({
  theme
}) => theme.colors.neutral[90], ({
  disabled
}) => disabled ? '0.4' : '1', ({
  disabled
}) => disabled ? 'not-allowed' : 'pointer', ({
  theme
}) => theme.colors.neutral[90]);